.recharts-legend-wrapper .recharts-default-legend .recharts-legend-item {
  cursor: pointer;
  margin-bottom: 7px;
}

.firepad .powered-by-firepad {
  display: none;
}

.firepad-toolbar {
  padding-left: 0;
}

.firepad-toolbar-wrapper .firepad-btn-group:last-child {
  display: none;
}

// iframe {
//   width: 100%;
//   height: 100%;
// }

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.slick-list {
  margin: 0 -3px;
}
.slick-slide > div {
  padding: 0 3px;
}
